<template>
    <div class="action-details" v-if="!isLoading">
      <div class="action-info">
        <h1 class="action-item">{{ actionData.item }}</h1>
        <div class="action-date">Assignee: {{ actionData.owner.name }}, Apartment {{ actionData.owner.apartment }}. </div>
        <div class="action-date">Due date: {{ actionData.due_date }}</div>
        <div class="action-date" v-if="actionData.created_by">Created By: {{ actionData.created_by.name }}, Apartment {{ actionData.created_by.apartment }}. </div>
        <div class="action-status">Status: {{ actionData.status }}</div>
        <div class="action-details">{{ actionData.details }}</div>
      </div>
      <div class="comments-section">
        <div v-for="comment in  commentsData " :key="comment.id" class="comment-card">
          <div class="comment-text">{{ comment.message }}</div>
          <div class="comment-footer">
            <div class="comment-author">Posted by {{ comment.created_by_name }}, Apartment {{ comment.created_by_apartment }} </div>
            <div class="actions-menu" v-if="userUID === comment.created_by_uid">
          <a-dropdown>
					<a class="ant-dropdown-link" @click="e => e.preventDefault()">
					Actions <a-icon type="down" />
					</a>
					<a-menu slot="overlay">
            <a-menu-item>
						<a href="javascript:;" v-on:click="DeleteComment(comment, actionData)">Delete</a>
					</a-menu-item>
          </a-menu>
          </a-dropdown>
          </div>
            <div class="comment-date">{{ formattedCommentDate(comment.created_timestamp) }}</div>
          </div>
        </div>
        <div class="add-comment">
          <textarea v-model="newCommentText" placeholder="Add a comment..."></textarea>
          <button :disabled="!newCommentText || isSubmitting" :class="{ 'button-disabled': !newCommentText || isSubmitting }" @click="submitComment">Post Comment</button>
        </div>
      </div>
    </div>
    <div v-else>Loading...</div>
  </template>
  
  <script>
	import { mapActions } from 'vuex'
	import { mapState } from 'vuex'
  export default {
   
    data() {
      return {
        isLoading: true,
        newCommentText: '',
        isSubmitting: false,
      };
    },
		async mounted() {
			window.analytics.page('Action Details');  
	  	await this.getUserData();
			await this.getActionThread({ action_id: this.$route.params.id, BuildingID: this.BuildingID});
      this.isLoading = false;
		},
		
    computed: {
			...mapState({
				actionData: state => state.actions.action,
				BuildingID: state => state.auth.user.building_id,
				personalInfo: state => state.profile.userInfo,
        commentsData: state => state.actions.comments_data,
        userUID: (state) => state.auth.user.uid,
			}),
      formattedActionDate() {
        const date = new Date(this.actionData.due_date);
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        });
      },
    },
    methods: {
        async DeleteComment(comment, actionData) {
			if(confirm("Do you really want to delete the comment? This can not be undone!")){
				console.log("deleting comment", comment.key);

				try {
					let res = await this.deleteComment({comment_id: comment.id, action_id: actionData.id, BuildingID: this.BuildingID});
					} catch (e) {
						console.log('modalHandleOk error', e)
					}
			}
        },
        ...mapActions('actions', [
          'addComment',
          'deleteComment',
          'getActionThread',
        ]),
			...mapActions('profile', ['getUserData']),

      formattedCommentDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        });
      },
      async submitComment() {
        // Add logic to submit the new comment
        try {
          this.isSubmitting = true;
          let comment = {}
          comment.message = this.newCommentText;
          comment.created_by_name = this.personalInfo.name;
          comment.created_by_apartment = this.personalInfo.apartment;
          comment.created_by_uid = this.personalInfo.uid;
					comment.created_by_email = this.personalInfo.email;
          let res = await this.addComment({
            action_id: this.actionData.id,
            comment: comment,
            BuildingID: this.BuildingID
          });
          if (res) {
            console.log('comment added');
          }
          else {
            console.log('comment not added');
          }
          this.newCommentText = '';
          this.isSubmitting = false;
        }
        catch (err) {
          console.log(err);
          this.isSubmitting = false;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .action-details {
    font-size: 2rem;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  
  .action-item {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }
  
  .action-date {
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }
  
  .action-content {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  
  .comment-card {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;
  }
  
  .comment-text {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    white-space: pre-wrap;
  }
  
  .comment-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.75rem;
    color: #666;
  }
  
  .add-comment {
    margin-top: 1rem;
  }
  
  .add-comment textarea {
    font-size: 1.5rem;
    padding: 1rem;
    width: 100%;
    height: 100px;
    resize: none;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add-comment button {
    background-color: #0077cc;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
  }

  .button-disabled {
  background-color: grey !important;
  color: #fff;
  cursor: not-allowed;
}

.action-status {
    font-size: 0.875rem;
    margin-bottom: 1rem;
}

</style>
  